const TierBenefitScreens = {
  TIER_BENEFIT: {
    PATH: '/tier-benefit',
    TITLE: 'Tier & Benefit',
    TITLE_ALT: 'Tier & Benefit',
    PERMISSION: 'tier-benefit.get-list',
  },
  LIST_TIER: {
    PATH: '/tier-benefit/master-tier',
    HEADER_NAME: 'master tier',
    TITLE: 'Master Tier',
    TITLE_ALT: 'Master Tier',
    PERMISSION: 'tier-benefit.get-list',
  },
  ADD_TIER: {
    PATH: '/tier-benefit/master-tier/add',
    HEADER_NAME: 'Create Master Tier',
    TITLE: 'Create Master Tier',
    TITLE_ALT: 'Create Master Tier',
    PERMISSION: 'tier-benefit.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_TIER: {
    PATH: '/tier-benefit/master-tier/detail/:id',
    HEADER_NAME: 'View Detail Master Tier',
    TITLE: 'View Detail Master Tier',
    TITLE_ALT: 'View Detail Master Tier',
    buildPath: (id: string) => `/tier-benefit/master-tier/detail/${id}`,
    PERMISSION: 'tier-benefit.view-detail',
    // breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_TIER: {
    PATH: '/tier-benefit/master-tier/edit/:id',
    HEADER_NAME: 'Edit Master Tier',
    TITLE: 'Edit Master Tier',
    TITLE_ALT: 'Edit Master Tier',
    buildPath: (id: string) => `/tier-benefit/master-tier/edit/${id}`,
    PERMISSION: 'tier-benefit.update',
    // breadcrumbs: defaultBreadcrumbs,
  },
  GAMIFICATION:{
    PATH: '/tier-benefit/gamification',
    TITLE: 'Gamification',
    TITLE_ALT: 'Gamification',
  },
  LIST_GAMIFICATION: {
    PATH: '/tier-benefit/gamification',
    HEADER_NAME: 'Gamification',
    TITLE: 'Gamification',
    TITLE_ALT: 'Gamification',
    PERMISSION: 'gamification.get-list'
  },
  ADD_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/add',
    HEADER_NAME: 'Create Gamification',
    TITLE: 'Create Gamification',
    TITLE_ALT: 'Create Gamification',
    PERMISSION: 'gamification.create',
    // breadcrumbs: defaultBreadcrumbs,
  },
  DETAIL_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/detail/:id',
    HEADER_NAME: 'View Detail Gamification',
    TITLE: 'View Detail Gamification',
    TITLE_ALT: 'View Detail Gamification',
    buildPath: (id: string) => `/tier-benefit/gamification/detail/${id}`,
    PERMISSION: 'gamification.view-detail',
    // breadcrumbs: defaultBreadcrumbs,
  },
  EDIT_GAMIFICATION: {
    PATH: '/tier-benefit/gamification/edit/:id',
    HEADER_NAME: 'Edit Gamification',
    TITLE: 'Edit Gamification',
    TITLE_ALT: 'Edit Gamification',
    buildPath: (id: string) => `/tier-benefit/gamification/edit/${id}`,
    PERMISSION: 'gamification.update',
    // breadcrumbs: defaultBreadcrumbs,
  },
  POINT_CONFIGURATION:{
    PATH: '/tier-benefit/point-configuration',
    TITLE: 'Point Configuration',
    TITLE_ALT: 'Point Configuration',
    PERMISSION: 'point-config.update',
  },
  DETAIL_POINT_CONFIGURATION: {
    PATH: '/tier-benefit/point-configuration/detail',
    HEADER_NAME: 'Point Configuration',
    TITLE: 'Point Configuration',
    TITLE_ALT: 'Point Configuration',
    PERMISSION: 'point-config.view-detail',
  },
  QUALIFICATION_CRITERIA:{
    PATH: '/tier-benefit/qualification-criteria',
    TITLE: 'Qualification Criteria',
    TITLE_ALT: 'Qualification Criteria',
    PERMISSION:'qualification.get-list'
  },
  ADD_QUALIFICATION_CRITERIA: {
    PATH: '/tier-benefit/qualification-criteria/add',
    HEADER_NAME: 'Create Qualification Criteria',
    TITLE: 'Create Qualification Criteria',
    TITLE_ALT: 'Create Qualification Criteria',
    PERMISSION:'qualification.create'
  },
  DETAIL_QUALIFICATION_CRITERIA: {
    PATH: '/tier-benefit/qualification-criteria/detail/:id',
    HEADER_NAME: 'View Detail Qualification Criteria',
    TITLE: 'View Detail Qualification Criteria',
    TITLE_ALT: 'View Detail Qualification Criteria',
    buildPath: (id: string) => `/tier-benefit/qualification-criteria/detail/${id}`,
    PERMISSION:'qualification.view-detail'
  },
  EDIT_QUALIFICATION_CRITERIA: {
    PATH: '/tier-benefit/qualification-criteria/edit/:id',
    HEADER_NAME: 'Edit Qualification Criteria',
    TITLE: 'Edit Qualification Criteria',
    TITLE_ALT: 'Edit Qualification Criteria',
    buildPath: (id: string) => `/tier-benefit/qualification-criteria/edit/${id}`,
    PERMISSION:'qualification.update'
  },

  REWARD_CATALOGUE:{
    PATH: '/tier-benefit/reward-catalogue',
    TITLE: 'Reward Catalogue',
    TITLE_ALT: 'Reward Catalogue',
    PERMISSION: 'reward-catalogue.get-list'
  },
  ADD_REWARD_CATALOGUE: {
    PATH: '/tier-benefit/reward-catalogue/add',
    HEADER_NAME: 'Create Reward Catalogue',
    TITLE: 'Create Reward Catalogue',
    TITLE_ALT: 'Create Reward Catalogue',
    PERMISSION: 'reward-catalogue.create'
  },
  DETAIL_REWARD_CATALOGUE: {
    PATH: '/tier-benefit/reward-catalogue/detail/:id',
    HEADER_NAME: 'View Detail Reward Catalogue',
    TITLE: 'View Detail Reward Catalogue',
    TITLE_ALT: 'View Detail Reward Catalogue',
    buildPath: (id: string) => `/tier-benefit/reward-catalogue/detail/${id}`,
    PERMISSION: 'reward-catalogue.view-detail'
  },
  EDIT_REWARD_CATALOGUE: {
    PATH: '/tier-benefit/reward-catalogue/edit/:id',
    HEADER_NAME: 'Edit Reward Catalogue',
    TITLE: 'Edit Reward Catalogue',
    TITLE_ALT: 'Edit Reward Catalogue',
    buildPath: (id: string) => `/tier-benefit/reward-catalogue/edit/${id}`,
    PERMISSION: 'reward-catalogue.update'
  },

  REFERRAL_CODE_OUTLET:{
    PATH: '/tier-benefit/referral-code-outlet',
    TITLE: 'Referral Code Outlet',
    TITLE_ALT: 'Referral Code Outlet',
    PERMISSION: 'referral-outlet.get-list',
  },
  ADD_REFERRAL_CODE_OUTLET: {
    PATH: '/tier-benefit/referral-code-outlet/add',
    HEADER_NAME: 'Create Referral Code Outlet',
    TITLE: 'Create Referral Code Outlet',
    TITLE_ALT: 'Create Referral Code Outlet',
    PERMISSION: 'referral-outlet.create',
  },
  DETAIL_REFERRAL_CODE_OUTLET: {
    PATH: '/tier-benefit/referral-code-outlet/detail/:id',
    HEADER_NAME: 'View Detail Referral Code Outlet',
    TITLE: 'View Detail Referral Code Outlet',
    TITLE_ALT: 'View Detail Referral Code Outlet',
    buildPath: (id: string) => `/tier-benefit/referral-code-outlet/detail/${id}`,
    PERMISSION: 'referral-outlet.view-detail',
  },
  EDIT_REFERRAL_CODE_OUTLET: {
    PATH: '/tier-benefit/referral-code-outlet/edit/:id',
    HEADER_NAME: 'Edit Referral Code Outlet',
    TITLE: 'Edit Referral Code Outlet',
    TITLE_ALT: 'Edit Referral Code Outlet',
    buildPath: (id: string) => `/tier-benefit/referral-code-outlet/edit/${id}`,
    PERMISSION: 'referral-outlet.update',
  },
  REFERRAL_CODE_MEMBER: {
    PATH: '/tier-benefit/referral-code-member',
    HEADER_NAME: 'Referral Code Member',
    TITLE: 'Referral Code Member',
    TITLE_ALT: 'Referral Code Member',
    PERMISSION: 'referral-member.view-detail',
  },
  EDIT_REFERRAL_CODE_MEMBER: {
    PATH: '/tier-benefit/referral-code-member/edit',
    HEADER_NAME: 'Edit Referral Code Member',
    TITLE: 'Edit Referral Code Member',
    TITLE_ALT: 'Edit Referral Code Member',
    PERMISSION: 'referral-member.update',
  },
  CAMPAIGN_CONFIGURATION:{
    PATH: '/tier-benefit/campaign-configuration',
    TITLE: 'Campaign Configuration',
    TITLE_ALT: 'Campaign Configuration',
  },
  ADD_CAMPAIGN_CONFIGURATION: {
    PATH: '/tier-benefit/campaign-configuration/add',
    HEADER_NAME: 'Create Campaign Configuration',
    TITLE: 'Create Campaign Configuration',
    TITLE_ALT: 'Create Campaign Configuration',
  },
  DETAIL_CAMPAIGN_CONFIGURATION: {
    PATH: '/tier-benefit/campaign-configuration/detail/:id',
    HEADER_NAME: 'View Detail Campaign Configuration',
    TITLE: 'View Detail Campaign Configuration',
    TITLE_ALT: 'View Detail Campaign Configuration',
    buildPath: (id: string) => `/tier-benefit/campaign-configuration/detail/${id}`,
  },
  EDIT_CAMPAIGN_CONFIGURATION: {
    PATH: '/tier-benefit/campaign-configuration/edit/:id',
    HEADER_NAME: 'Edit Campaign Configuration',
    TITLE: 'Edit Campaign Configuration',
    TITLE_ALT: 'Edit Campaign Configuration',
    buildPath: (id: string) => `/tier-benefit/campaign-configuration/edit/${id}`,
  }
}

export default TierBenefitScreens
