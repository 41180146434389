import { toAbsoluteUrl } from "src/app/utils/asset-helpers-utils";
import { ImageProps } from "./Image.types";

export const defaultSrc = toAbsoluteUrl('/media/images/menu-placeholder.png');

export function Image(
  {
    fallbackSrc = defaultSrc,
    alt,
    ...props
  }: Readonly<ImageProps>
) {

  return (
    <img
      onError={(_) => { _.currentTarget.src = fallbackSrc; }}
      src={defaultSrc}
      alt={alt}
      {...props}
    />
  );
}
