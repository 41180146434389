import { getIn } from "formik";
import { FileRejection } from "react-dropzone";
import { useFileUpload } from "src/app/hooks/file-upload.hook";
import { validateImage, ValidateImageParams } from "src/app/utils/input-utils";
import { twMerge } from "tailwind-merge";
import { InputImageProps } from "../FormBuilder";
import { FormUploadImage } from "./FormUploadImage";

export function ValidateFormUploadImage({
  value,
  validateImage: _validateImage = {},
  message = 'Upload Image (JPG/PNG)',
  requirements,
  imgClassname,
  acceptFile,
  actionButtonPlacement,
  commonUpload,
  formik,
  formUploadImageClassName,
  ...props
}: Readonly<InputImageProps>) {

  const imageUpload = useFileUpload();

  const name = `${value}`;
  const previewName = `${name}_preview`;

  const resetValue = () => {
    formik.setFieldValue(previewName, '');
    formik.setFieldValue(name, '');
  };

  const getCommonUpload = async (field: string, value: any, shouldValidate?: boolean) => {
    try {

      if (commonUpload && field === name) {
        const data = await imageUpload.upload({ file: value });
        formik.setFieldValue(field, data?.name, shouldValidate);
      } else {
        formik.setFieldValue(field, value, shouldValidate);
      }

    } catch (error) {
      formik.setFieldError(name, 'Unable to upload image');
      resetValue();
    }
    setTimeout(
      () => formik.setFieldTouched(field, true),
      250
    );
  }

  const validateImageParams: ValidateImageParams = {
    field: name,
    preview_field: `${previewName}`,
    setFieldTouched: formik.setFieldTouched,
    setFieldError: formik.setFieldError,
    setFieldValue: getCommonUpload,
    maxSize: 1,
    ratio: '16/9',
    ..._validateImage
  };

  const handleDropImage = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    validateImage({
      image: acceptedFiles[0],
      rejects: rejectedFiles,
      errorRatio: _validateImage.errorRatio,
      errorFormat: _validateImage.errorFormat,
      errorSize: _validateImage.errorSize,
      ...validateImageParams
    });
  };
  const ratioStyle = () => {
    switch (_validateImage.ratio) {
      case '9/16':
        return 'w-60 aspect-[9/16]';
      case '16/9':
        return 'h-[220px] aspect-[16/9]';
      case '1/1':
        return 'h-60 aspect-[1/1]';
      default:
        return 'h-60';
    }
  };

  return (
    <div className={twMerge('flex items-center', imgClassname)}>
      <FormUploadImage
        {...props}
        title='Image'
        message={message}
        onDrop={handleDropImage}
        acceptFile={acceptFile}
        onClear={resetValue}
        preview={getIn(formik.values, previewName)}
        className={twMerge(ratioStyle(), formUploadImageClassName)}
        actionButtonPlacement={actionButtonPlacement}
        loading={imageUpload.isLoading}
      />
      <div className='grow'>
        <ul className='text-sm leading-8 list-disc'>
          {Array.isArray(requirements) ?
            requirements.map((_) => <li key={_?.toString()}>{_}</li>) :
            <>
              <li>
                <span className='underline'>Size:</span> {requirements?.maxSize ?? `Max size of file is ${validateImageParams?.maxSize} MB.`}
              </li>
              <li>
                <span className='underline'>File format:</span> {requirements?.format ?? "only allow .jpg & .png"}
              </li>
              <li>
                <span className='underline'>File format:</span> {requirements?.ratio ?? `${validateImageParams?.ratio} Ratio`}
              </li>
            </>
          }
        </ul>
      </div>
    </div>
  );

}
