import clsx from 'clsx'
import { useMemo } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { useMasterLayout } from 'src/app/context/MasterContext'
import CustomerListScreens from 'src/app/modules/customer/Screens'
import DashboardScreens from 'src/app/modules/dashboard/Screens'
import GeneralConfigScreens from 'src/app/modules/general_config/Screens'
import InstantRewardsScreens from 'src/app/modules/instant_rewards/InstantRewardsScreens'
import MenuManagementScreens, { ListBestSellerTab } from 'src/app/modules/menu_management/Screens'
import OnboardingScreens from 'src/app/modules/onboarding/Screens'
import OrderScreens from 'src/app/modules/order/Screens'
import OutletScreens from 'src/app/modules/outlet/Screens'
import { Admin } from 'src/app/modules/permissions/models/Admin.model'
import { ModulePermissionQuery } from 'src/app/modules/permissions/models/Role.model'
import AdminRoleScreens from 'src/app/modules/permissions/Screens'
import PromotionScreens from 'src/app/modules/promotion/Screens'
import SplashscreenScreens from 'src/app/modules/splash_screen/Screens'
import TierBenefitScreens from 'src/app/modules/tier_benefit/Screens'
import VoucherScreens from 'src/app/modules/voucher/Screens'
import { RootState } from 'src/setup'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

interface Menu {
  id?: string
  to?: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: Menu[]
  module_permissions?: ModulePermissionQuery
  activePath?: string
  badge?: string
  badgeCircle?: boolean
  hidden?: boolean
  disabled?: boolean
}

const useMenus = (user?: Admin): Menu[] => {
  return useMemo(
    () =>
      [
        {
          children: [
            {
              to: DashboardScreens.DASHBOARD.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: DashboardScreens.DASHBOARD.TITLE,
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: AdminRoleScreens.USER_ROLE.PATH,
              icon: '/media/icons/IconUser.svg',
              title: AdminRoleScreens.USER_ROLE.TITLE,
              children: [
                {
                  to: AdminRoleScreens.LIST_ROLE.PATH,
                  activePath: AdminRoleScreens.LIST_ROLE.PATH,
                  title: AdminRoleScreens.LIST_ROLE.TITLE,
                  hasBullet: false,
                  module_permissions: AdminRoleScreens.LIST_ROLE.PERMISSION,
                },
                {
                  to: AdminRoleScreens.LIST_ADMIN.PATH,
                  activePath: AdminRoleScreens.LIST_ADMIN.PATH,
                  title: AdminRoleScreens.LIST_ADMIN.TITLE,
                  hasBullet: false,
                  module_permissions: AdminRoleScreens.LIST_ADMIN.PERMISSION,
                },
              ],
            },
            {
              to: CustomerListScreens.CUSTOMER.PATH,
              icon: '/media/icons/IconCustomer.svg',
              title: CustomerListScreens.CUSTOMER.TITLE,
              children: [
                {
                  to: CustomerListScreens.LIST_CUSTOMER.PATH,
                  activePath: CustomerListScreens.LIST_CUSTOMER.PATH,
                  title: CustomerListScreens.LIST_CUSTOMER.TITLE,
                  hasBullet: false,
                  module_permissions: CustomerListScreens.LIST_CUSTOMER.PERMISSION,
                }
              ]
            },
            {
              to: PromotionScreens.PROMOTION.PATH,
              icon: '/media/icons/IconPromotion.svg',
              title: PromotionScreens.PROMOTION.TITLE,
              children: [
                {
                  to: `${PromotionScreens.PROMOTION.PATH}/banner/list/featured`,
                  activePath: `${PromotionScreens.PROMOTION.PATH}/banner`,
                  title: PromotionScreens.MAIN_BANNER.TITLE_ALT,
                  hasBullet: false,
                  module_permissions: true,
                },
                {
                  to: PromotionScreens.LIST_SECTION_BANNER.ONGOING,
                  activePath: PromotionScreens.SECTION_BANNER.PATH,
                  title: PromotionScreens.SECTION_BANNER.TITLE,
                  hasBullet: false,
                  module_permissions: true,
                },
                {
                  to: PromotionScreens.LIST_PROMO_BANNER.PATH,
                  activePath: PromotionScreens.PROMO_BANNER.PATH,
                  title: PromotionScreens.PROMO_BANNER.TITLE,
                  hasBullet: false,
                  module_permissions: PromotionScreens.PROMO_BANNER.PERMISSION,
                },
                {
                  to: PromotionScreens.POPUP_BANNER.FEATURED,
                  activePath: PromotionScreens.POPUP_BANNER.PATH,
                  title: PromotionScreens.POPUP_BANNER.TITLE,
                  hasBullet: false,
                  module_permissions: PromotionScreens.POPUP_BANNER.PERMISSION,
                }
              ],
            },
            {
              to: InstantRewardsScreens.INSTANT_REWARDS.PATH,
              icon: '/media/icons/IconDashboard.svg',
              title: InstantRewardsScreens.INSTANT_REWARDS.TITLE,
              module_permissions: InstantRewardsScreens.INSTANT_REWARDS.PERMISSION,
            },
            {
              to: VoucherScreens.VOUCHER.PATH,
              icon: '/media/icons/IconVoucher.svg',
              title: VoucherScreens.VOUCHER.TITLE,
              children: [
                {
                  to: VoucherScreens.LIST_VOUCHER.PATH,
                  activePath: VoucherScreens.LIST_VOUCHER.PATH,
                  title: VoucherScreens.LIST_VOUCHER.TITLE,
                  hasBullet: false,
                  module_permissions: VoucherScreens.LIST_VOUCHER.PERMISSION,
                },
                {
                  to: VoucherScreens.COMBINATION.PATH,
                  activePath: VoucherScreens.COMBINATION.PATH,
                  title: VoucherScreens.COMBINATION.TITLE,
                  hasBullet: false,
                  module_permissions: VoucherScreens.COMBINATION.PERMISSION,
                }
              ]
            },
            {
              to: TierBenefitScreens.TIER_BENEFIT.PATH,
              icon: '/media/icons/IconTier.svg',
              title: TierBenefitScreens.TIER_BENEFIT.TITLE,
              children: [
                {
                  to: TierBenefitScreens.LIST_TIER.PATH,
                  activePath: TierBenefitScreens.LIST_TIER.PATH,
                  title: TierBenefitScreens.LIST_TIER.TITLE,
                  hasBullet: false,
                  module_permissions: TierBenefitScreens.LIST_TIER.PERMISSION,
                },
                {
                  to: TierBenefitScreens.LIST_GAMIFICATION.PATH,
                  activePath: TierBenefitScreens.LIST_GAMIFICATION.PATH,
                  title: TierBenefitScreens.LIST_GAMIFICATION.TITLE,
                  hasBullet: false,
                  module_permissions: TierBenefitScreens.LIST_GAMIFICATION.PERMISSION,
                },
                {
                  to: TierBenefitScreens.DETAIL_POINT_CONFIGURATION.PATH,
                  activePath: TierBenefitScreens.DETAIL_POINT_CONFIGURATION.PATH,
                  title: TierBenefitScreens.POINT_CONFIGURATION.TITLE,
                  hasBullet: false,
                  module_permissions: TierBenefitScreens.POINT_CONFIGURATION.PERMISSION,
                },
                {
                  to: TierBenefitScreens.QUALIFICATION_CRITERIA.PATH,
                  activePath: TierBenefitScreens.QUALIFICATION_CRITERIA.PATH,
                  title: TierBenefitScreens.QUALIFICATION_CRITERIA.TITLE,
                  // hasBullet: false,
                  module_permissions: TierBenefitScreens.QUALIFICATION_CRITERIA.PERMISSION,
                },
                {
                  to: TierBenefitScreens.REWARD_CATALOGUE.PATH,
                  activePath: TierBenefitScreens.REWARD_CATALOGUE.PATH,
                  title: TierBenefitScreens.REWARD_CATALOGUE.TITLE,
                  module_permissions: TierBenefitScreens.REWARD_CATALOGUE.PERMISSION,
                },
                {
                  to: TierBenefitScreens.REFERRAL_CODE_OUTLET.PATH,
                  activePath: TierBenefitScreens.REFERRAL_CODE_OUTLET.PATH,
                  title: TierBenefitScreens.REFERRAL_CODE_OUTLET.TITLE,
                  module_permissions: TierBenefitScreens.REFERRAL_CODE_OUTLET.PERMISSION,
                },
                {
                  to: TierBenefitScreens.REFERRAL_CODE_MEMBER.PATH,
                  activePath: TierBenefitScreens.REFERRAL_CODE_MEMBER.PATH,
                  title: TierBenefitScreens.REFERRAL_CODE_MEMBER.TITLE,
                  module_permissions: TierBenefitScreens.REFERRAL_CODE_MEMBER.PERMISSION,
                },
                {
                  to: TierBenefitScreens.CAMPAIGN_CONFIGURATION.PATH,
                  activePath: TierBenefitScreens.CAMPAIGN_CONFIGURATION.PATH,
                  title: TierBenefitScreens.CAMPAIGN_CONFIGURATION.TITLE,
                }
              ]
            },
            {
              to: OrderScreens.ORDER_LIST.PATH,
              icon: '/media/icons/IconOrder.svg',
              title: OrderScreens.ORDER_LIST.TITLE,
              module_permissions: DashboardScreens.DASHBOARD.PERMISSION,
            },
            {
              to: OutletScreens.OUTLET.PATH,
              icon: '/media/icons/IconOutlet.svg',
              title: OutletScreens.OUTLET.TITLE_ALT,
              children: [
                {
                  to: OutletScreens.LIST_OUTLET.PATH,
                  activePath: OutletScreens.LIST_OUTLET.PATH,
                  title: OutletScreens.LIST_OUTLET.TITLE,
                  hasBullet: false,
                  module_permissions: OutletScreens.LIST_OUTLET.PERMISSION,
                }
              ]
            },
            {
              to: MenuManagementScreens.MENU_MANAGEMENT.PATH,
              icon: '/media/icons_v2/base/menu-3.svg',
              title: MenuManagementScreens.MENU_MANAGEMENT.TITLE,
              children: [
                {
                  to: MenuManagementScreens.MENU_LIST.PATH,
                  activePath: MenuManagementScreens.MENU_LIST.PATH,
                  title: MenuManagementScreens.MENU_LIST.TITLE,
                  hasBullet: false,
                  module_permissions: MenuManagementScreens.MENU_LIST.PERMISSION,
                },
                {
                  to: ListBestSellerTab.FEATURED_BEST_SELLER.PATH,
                  activePath: ListBestSellerTab.FEATURED_BEST_SELLER.PATH,
                  title: MenuManagementScreens.BEST_SELLER.TITLE,
                  hasBullet: false,
                  module_permissions: ListBestSellerTab.FEATURED_BEST_SELLER.PERMISSION,
                },
              ]
            },
            {
              to: GeneralConfigScreens.GENERAL_CONFIG.PATH,
              icon: '/media/icons_v2/base/settings.svg',
              title: GeneralConfigScreens.GENERAL_CONFIG.TITLE,
              children: [
                {
                  to: SplashscreenScreens.SPLASHSCREEN.PATH,
                  activePath: SplashscreenScreens.SPLASHSCREEN.PATH,
                  hasBullet: false,
                  title: SplashscreenScreens.SPLASHSCREEN.HEADER_NAME,
                  module_permissions: SplashscreenScreens.SPLASHSCREEN.PERMISSION,
                },
                {
                  to: OnboardingScreens.ONBOARDING_LIST.PATH,
                  activePath: OnboardingScreens.ONBOARDING_LIST.PATH,
                  hasBullet: false,
                  title: OnboardingScreens.ONBOARDING_LIST.TITLE,
                  module_permissions: OnboardingScreens.ONBOARDING_LIST.PERMISSION,
                },
                {
                  to: GeneralConfigScreens.TNC.PATH,
                  activePath: GeneralConfigScreens.TNC.PATH,
                  title: GeneralConfigScreens.TNC.TITLE,
                  hasBullet: false,
                  module_permissions: GeneralConfigScreens.TNC.PERMISSION,
                },
                {
                  to: GeneralConfigScreens.FAQ.PATH,
                  activePath: GeneralConfigScreens.FAQ.PATH,
                  title: GeneralConfigScreens.FAQ.TITLE,
                  hasBullet: false,
                  module_permissions: GeneralConfigScreens.FAQ.PERMISSION,
                },
                {
                  to: GeneralConfigScreens.PUSH_NOTIFICATION_LIST.BROADCAST_PATH,
                  activePath: GeneralConfigScreens.PUSH_NOTIFICATION.PATH,
                  title: GeneralConfigScreens.PUSH_NOTIFICATION.TITLE,
                  hasBullet: false,
                  module_permissions: GeneralConfigScreens.PUSH_NOTIFICATION.PERMISSION,
                },
                {
                  to: GeneralConfigScreens.PRIVACY_POLICY.PATH,
                  activePath: GeneralConfigScreens.PRIVACY_POLICY.PATH,
                  title: GeneralConfigScreens.PRIVACY_POLICY.TITLE,
                  hasBullet: false,
                  module_permissions: GeneralConfigScreens.PRIVACY_POLICY.PERMISSION,
                },
                {
                  to: GeneralConfigScreens.VERSION_MANAGEMENT.PATH,
                  activePath: GeneralConfigScreens.VERSION_MANAGEMENT.PATH,
                  title: GeneralConfigScreens.VERSION_MANAGEMENT.TITLE,
                  hasBullet: false,
                  module_permissions: GeneralConfigScreens.VERSION_MANAGEMENT.PERMISSION,
                }
              ]
            },
          ],
        },
      ] as Menu[],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  )
}

const GeneratedMenu: React.FC<{ menu: Menu, isChildren?: boolean }> = ({ menu, isChildren }) => {
  const { minimize } = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={clsx('tracking-widest text-white uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        fontIcon={menu.fontIcon}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
        badge={menu.badge}
        badgeCircle={menu.badgeCircle}
        disabled={menu.disabled}
        isChildren={isChildren}
      />
    )

  return (
    <AsideMenuItemWithSub
      to={menu.to}
      title={menu.title}
      fontIcon={menu.fontIcon}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
      disabled={menu.disabled}
      isChildren={isChildren}
    >
      {menu.children.map((child) => {
        return <GeneratedMenu isChildren={true} menu={child} key={child.id} />
      })}
    </AsideMenuItemWithSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: Admin = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as Admin
  const menus = useMenus(user)

  const generated = useMemo(() => filterMenus(menus, (menu) => !menu.hidden), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
